import React, { useState } from 'react';
import { Link, useIntl } from 'gatsby-plugin-intl';
import './index.scss';
import RightNav from './nav';

export default function header({ children }) {
  const intl = useIntl();
  const [mobileMenu, setmobileMenu] = useState(false);

  const openMobileMenu = () => {
    setmobileMenu(!mobileMenu);
  };

  return (
    <>
      <header className="site-header js-site-header js-editable editable  js-fixed-nav">
        <button
          type="button"
          className={`navbar-toggler ${mobileMenu ? 'open' : ''}`}
          onClick={openMobileMenu}
        >
          <small>Menu</small>
        </button>
        <div className={`navbar-collapse ${mobileMenu ? 'show' : ''}`}>
          <nav className="nav-container">
            <ul>
              <li className="nav-title">
                <Link to="/" activeClassName="active">
                  {intl.formatMessage({ id: 'nav.home' })}
                </Link>
              </li>
              <li className="nav-title">
                <Link to="/support" activeClassName="active">
                  {intl.formatMessage({ id: 'nav.support' })}
                </Link>
              </li>
              <li className="nav-title">
                <Link to="/blog" partiallyActive activeClassName="active">
                  {intl.formatMessage({ id: 'nav.blog' })}
                </Link>
              </li>
              <li className="nav-title">
                <Link
                  to="/press-release"
                  partiallyActive
                  activeClassName="active"
                >
                  {intl.formatMessage({ id: 'nav.pr' })}
                </Link>
              </li>
              <li className="nav-title">
                <Link
                  to="/calculators/emi"
                  partiallyActive
                  activeClassName="active"
                >
                  {intl.formatMessage({ id: 'nav.calculators' })}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <RightNav>{children}</RightNav>
      </header>
    </>
  );
}
