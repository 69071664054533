const config = {
  siteTitle: "DanfeBooks - Free Hybrid HRM + Payroll Accounting Software | Nepal ",
  appName: "DanfeBooks®",
  siteTitleShort: "danfebooks",
  siteTitleAlt: "DanfeBooks - Free Hybrid HRM + Payroll Accounting Software | Nepal ", // Alternative site title for SEO.
  siteDescription: "DanfeBooks is a Hybrid HRM, HRIS and Payroll automation software specially built to adhere to Nepal's Taxation Law and Nepali market. Start free today.", // Website description used for RSS feeds/meta description tag.
  sitePreviewImage: "/logos/og_website_preview.png", // Logo used for SEO and manifest.
  siteLogo: "/logos/logo-192.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.danfebooks.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  googleAnalyticsID: "UA-102100151-1", // GA tracking ID.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  blogPostsPerPage: 10, // Amount of posts displayed per listing page.
  pressReleasePerPage: 10, // Amount of press release posts displayed per listing page.
  userName: "DanfeBooks", // Username to display in the author segment.
  userEmail: "humans@danfebooks.com", // Email used for RSS feed"s author segment  
  userTwitter: "@danfebooks", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Asia/Kathmandu", // User location to display in the author segment.
  userDescription: "DanfeBooks - Free Hybrid HRM + Payroll Accounting Software | Nepal ", // User description to display in the author segment.
  copyright: "Copyright © 2017 - 2023. Optimum Futurist Tech Pvt. Ltd. - All rights reserved.", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#926dbb", // Used for setting manifest and progress theme colors.
  backgroundColor: "#fcfaff" // Used for setting manifest background color.
};

if (process.env.LANG_ENV === "ne") {
  config.siteUrl = "https://www.danfebooks.com.np"
}
// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn"t have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

  
module.exports = config;
