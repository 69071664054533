/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';

export default function footer(props) {
  const { children } = props;
  const intl = useIntl();
  return (
    <footer
      className={
        children?.type === 'nav'
          ? 'site-footer-wrap has-nav'
          : 'site-footer-wrap no-nav'
      }
    >
      <div className="site-container">
        <div className="site-content">
          {children}
          <div className="site-footer">
            <div className="footer-text">
              <p>
                {intl.formatMessage({ id: 'footer.madeWith' })}
                <span role="img" aria-label="Love" style={{ color: '#e20202' }}>
                  ❤️
                </span>
                {intl.formatMessage({ id: 'footer.inNepal' })}
              </p>
              <p className="lead">
                {`${intl.formatMessage({
                  id: 'footer.copyright',
                })} - ${intl.formatMessage({ id: 'footer.companyName' })}`}
                {` `}&nbsp; &bull;{` `}
                <Link to="/privacy-policy">
                  {intl.formatMessage({ id: 'footer.privacyPolicy' })}
                </Link>
                <Link to="/terms-of-service">
                  {intl.formatMessage({ id: 'footer.termsofServices' })}
                </Link>
                <Link
                  to="/user-agreement"
                  title={intl.formatMessage({ id: 'footer.eulaFull' })}
                >
                  {intl.formatMessage({ id: 'footer.eula' })}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
