import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, useIntl, IntlContextConsumer } from 'gatsby-plugin-intl';
import config from '../../data/SiteConfig';
import Header from '../components/Header';
import './globalStyle/home.css';
import './globalStyle/site.css';

const languageName = {
  en: '🇺🇸',
  ne: '🇳🇵',
};

const currentLang = process.env.LANG_ENV === `ne` ? `ne` : `en`;

const MainLayout = ({ children }) => {
  const intl = useIntl();
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <div className={`intl-${currentLocale}`}>
          <Header>
            <span className="label">
              {intl.formatMessage({ id: 'nav.langLabel' })}
            </span>
            <a
              className={`btn ${currentLang === 'en' && 'lang-active'}`}
              href="https://www.danfebooks.com"
            >
              {languageName.en}
            </a>
            <a
              className={`btn ${currentLang === 'ne' && 'lang-active'}`}
              href="https://www.danfebooks.com.np"
            >
              {languageName.ne}
            </a>
          </Header>
          <Helmet>
            <html lang={currentLocale} />
            <meta name="description" content={config.siteDescription} />
          </Helmet>
          {children}
        </div>
      )}
    </IntlContextConsumer>
  );
};

export default injectIntl(MainLayout);
